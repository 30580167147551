<template>
  <div class="about">
    <!-- <titles></titles> -->
    <div class="navAll">
      <div class="nav">
        <img src="@/assets/logo_big.png" class="logo">
        <div class="msg" @click="messageHandle">业务咨询</div>
      </div>
    </div>
    <div class="banner">
      <img src="@/assets/bannerMessage.png" class="bannerMessage" />
      <div class="wordAll">
        <h1 class="title" style="font-weight: bold;">人力资源外包</h1>
        <div class="pWord">一站式劳务用工解决方案：人员外包、入/离职、社保、工资、劳务合同管理、纠纷处理等</div>
        <div class="spanWord">One-stop labor solutions including personnel outsourcing, entry/exit, social security, wages, labor contract management, dispute resolution, etc.</div>
      </div>
    </div>

    <div class="business">
      <img src="@/assets/business1.png" class="businessImg" />
      <div class="businessRight">
        <div class="businessTitle">业务咨询</div>
        <div class="businessContent">您的资料已受保护，请放心填写</div>
        <div class="businessForm">
          <el-form :model="messageData" :rules="messageDataRules" ref="messageData">
            <el-form-item class="message_item" prop="contacts">
              <div class="bitian">
                <span class="red">*</span>
                姓名
              </div>
              <el-input class="bitianInput" v-model="messageData.contacts" placeholder="您的称呼"></el-input>
            </el-form-item>
            <el-form-item class="message_item" prop="phone">
              <div class="bitian">
                <span class="red">*</span>
                电话
              </div>
              <el-input class="bitianInput" v-model="messageData.phone" placeholder="您的联系方式"></el-input>
            </el-form-item>
            <el-form-item class="message_item" prop="type">
              <div class="bitian">
                <span class="red">*</span>
                您是
              </div>
              <el-radio-group class="bitianInput" v-model="messageData.type">
                <el-radio label="公司"></el-radio>
                <el-radio label="个人"></el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="message_submit" @click="messageSubmit">立即提交</div>
          </el-form>
        </div>
      </div>
    </div>

    <div class="serveColor">
      <div class="serve">
        <div class="serveTop">
          <div class="serveTitle">企业员工到岗离岗全程支持外包用工 </div>
          <div class="lineRed"></div>
        </div>
        <div class="serveAll">
          <div class="serveItem">
            <img src="@/assets/serve1.png" class="serveImg">
            <div class="serveFlex">
              <div class="wordTitle">员工招聘服务</div>
              <div class="wordTitleContent">解决招聘难题，根据业务需求计划增减员</div>
            </div>
          </div>
          <div class="serveItem">
            <img src="@/assets/serve2.png" class="serveImg">
            <div class="serveFlex">
              <div class="wordTitle">员工社保服务</div>
              <div class="wordTitleContent">社保账户开立、托管、增减员、异地员工缴纳、用工备案</div>
            </div>
          </div>
          <div class="serveItem">
            <img src="@/assets/serve3.png" class="serveImg">
            <div class="serveFlex">
              <div class="wordTitle">薪酬个税服务</div>
              <div class="wordTitleContent">本地/异地智能化算薪，工资条在线发放、报税、优化薪资结构</div>
            </div>
          </div>
          <div class="serveItem">
            <img src="@/assets/serve4.png" class="serveImg">
            <div class="serveFlex">
              <div class="wordTitle">用工风险管理</div>
              <div class="wordTitleContent">劳动合同管理、劳资纠纷预防、调节、争议处理、工伤处理</div>
            </div>
          </div>
        </div>
      </div>    
    </div>

    <div class="supplier">
      <div class="supplierTitle">“企业是否面临以下问题？”</div>
      <div class="flex">
        <img src="@/assets/Rectangle2108.png" class="left">
        <div class="question">
          <div class="questionItem">
            <div class="wordS">1.员工流动率高，招聘难度大、成本高</div>
            <img src="@/assets/Frame.png" class="Frame">
          </div>
          <div class="questionItem">
            <div class="wordS">2.薪资支付流程复杂，不够精确</div>
            <img src="@/assets/Frame.png" class="Frame">
          </div>
          <div class="questionItem">
            <div class="wordS">3.人事专员能力欠佳，突发事务多</div>
            <img src="@/assets/Frame.png" class="Frame">
          </div>
          <div class="questionItem">
            <div class="wordS">4.企业跨地区分支机构员工事务难以统筹管理</div>
            <img src="@/assets/Frame.png" class="Frame">
          </div>
          <div class="questionItem">
            <div class="wordS">5.劳资双方关系紧张，劳动纠纷繁杂</div>
            <img src="@/assets/Frame.png" class="Frame">
          </div>
          <div class="questionItem">
            <div class="wordS">6.存在短时临时用工需求</div>
            <img src="@/assets/Frame.png" class="Frame">
          </div>
        </div>
      </div>
    </div>

    <div class="cost">
      <div class="costTitle">
        <div class="costBackground">SELECT BANCMANG</div>
      </div>
      <div class="Group">
        <div class="GroupItem">
          <div class="GroupWord">17年丰富服务经验</div>
          <div class="GroupBottom">
            <div class="GroupWord2">海量合作案例</div>
            <div class="GroupWord2">更成熟专业</div>
            <img src="@/assets/Group1.png" class="GroupImg">
          </div>
        </div>
        <div class="GroupItem">
          <div class="GroupWord">
            <div>全国实体直营</div>
            <div>400城市网点落地服务</div>
          </div>
          <div class="GroupBottom">
            <div class="GroupWord2">直营落地服务</div>
            <div class="GroupWord2">便捷高效</div>
            <img src="@/assets/Group2.png" class="GroupImg">
          </div>
        </div>
        <div class="GroupItem">
          <div class="GroupWord">降低用工风险</div>
          <div class="GroupBottom">
            <div class="GroupWord2">人力成本转为外包成本</div>
            <div class="GroupWord2">有效降低成本</div>
            <img src="@/assets/Group3.png" class="GroupImg">
          </div>
        </div>
        <div class="GroupItem">
          <div class="GroupWord">专业合规资质</div>
          <div class="GroupBottom">
            <div class="GroupWord2">正规人力资源许可</div>
            <div class="GroupWord2">可靠放心</div>
            <img src="@/assets/Group4.png" class="GroupImg">
          </div>
        </div>
        <div class="GroupItem">
          <div class="GroupWord">专属客服顾问</div>
          <div class="GroupBottom">
            <div class="GroupWord2">一对一客服</div>
            <div class="GroupWord2">跟踪管理服务</div>
            <img src="@/assets/Group5.png" class="GroupImg">
          </div>
        </div>
      </div>
    </div>

    <div class="num">
      <div class="numItem">
        <div class="numTitle">深耕行业</div>
        <div class="numFlex">
          <div class="numWord">17</div>
          <div class="numUnit">年</div>
        </div>
      </div>
      <div class="numItem">
        <div class="numTitle">直营公司</div>
        <div class="numFlex">
          <div class="numWord">228</div>
          <div class="numUnit">+</div>
        </div>
      </div>
      <div class="numItem">
        <div class="numTitle">覆盖城市</div>
        <div class="numFlex">
          <div class="numWord">400</div>
          <div class="numUnit">+</div>
        </div>
      </div>
      <div class="numItem">
        <div class="numTitle">专业团队</div>
        <div class="numFlex">
          <div class="numWord">2600</div>
          <div class="numUnit">+</div>
        </div>
      </div>
      <div class="numItem">
        <div class="numTitle">服务客户</div>
        <div class="numFlex">
          <div class="numWord">30000</div>
          <div class="numUnit">+</div>
        </div>
      </div>
      <div class="numItem">
        <div class="numTitle">雇员数量</div>
        <div class="numFlex">
          <div class="numWord">1000000</div>
          <div class="numUnit">+</div>
        </div>
      </div>
    </div>
    
    <div class="swiper" style="margin-bottom: 100px;">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="detail">
            <div class="detailItem">
              <div class="detailTitle">能源化工行业顶尖企业</div>
              <div class="detailP">服务客户</div>
              <div class="detailWord">知名物流企业，中国500强，全球员工超10万人。业务涉及快运、整车、仓储与供应链等。</div>
              <div class="detailP">客户痛点</div>
              <div class="detailWord">1.员工基数大，零散分布在全国百余个城市，入离职及各类社保事务的扣缴、申领等手续纷繁复杂</div>
              <div class="detailWord">2.各地劳动纠纷案件频发，异地处理人力物力成本耗费高</div>
              <div class="detailWord">3.一线员工人员流动率大，不稳定性强，招聘压力大</div>
              <div class="detailP">解决方案</div>
              <div class="detailWord detailContent">
                邦芒为客户配置经验丰富的客户经理作为第一对接人，客户的全国落地业务均可直接与第一对接人沟通，真正实现一地委托，全国服务。
              </div>
              <div class="detailWord detailContent">
                利用线上网签系统，员工劳动合同的签订实现无纸化和远程化，合同文件管理方便便捷，数万员工合同均实现了在线签约和存档管理，极大地改善了原有合同签
              </div>
              <div class="detailWord detailContent">
                订费时费力且保管过程复杂等问题，降低了客户人力资源部50%以上的基础工作量。
              </div>
              <div class="detailWord detailContent">
                邦芒运用自主研发的人力资源信息管理系统--人事宝云平台，集成全国400+城市社保、公积金等各项人力资源政策方案，一键申报海量员工社保、公积金的增员、
              </div>
              <div class="detailWord detailContent">
                减员情况，一键算薪、个税申报及准确及时发薪，邦芒旗下的企业一站式法律外包及咨询顾问律师服务平台--宝威法律，配备20人的律师团队和相应的顾问、行政
              </div>
              <div class="detailWord detailContent">
                团队，凭借着在劳动合同的审核和起草;劳动纠纷的处理等方面的丰富实战经验，为客户提供了完善的人事法律咨询和顾问服务。
              </div>
              <div class="detailWord detailContent">
                邦芒通过228家分公司运营的多家人力资源市场，结合线上的邦芒直聘平台，共同搭建完整高效的招聘矩阵，在全国400+城市同步协作招聘，为客户尤其是在一
              </div>
              <div class="detailWord detailContent">
                线的招聘人员提供极大助力。服务期间，对于原有站点的人员补位和新站点的人员新招，做到了90%以上招聘到响应。
              </div>
              <img src="@/assets/enterprise1.png" class="detailImg">
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="detail">
            <div class="detailItem">
              <div class="detailTitle">居民服务行业翘楚</div>
              <div class="detailP">服务客户</div>
              <div class="detailWord">知名居民服务企业，截至现在，门店规模覆盖全国近百个城市，拥有近700家直营门店。</div>
              <div class="detailP">客户痛点</div>
              <div class="detailWord">1.员工基数大，覆盖城市广，员工的社保公积金增减、社保相关事务处理耗费大量精力</div>
              <div class="detailWord">2.各地政策复杂多变，人力耗费成本高</div>
              <div class="detailWord">3.公司发展迅速，市场不断扩张，吸需员工社保解决方案</div>
              <div class="detailP">解决方案</div>
              <div class="detailWord detailContent">
                48小时内成立专项项目组，对现有人员进行理，准确及时完成人员的参保转移。同时建立漏缴断缴防控机制，保证参减保工作百分百无遗漏。
              </div>
              <div class="detailWord detailContent">
                邦芒运用自主研发的人力资源信息管理系统--人事宝云平台，集成全国400+城市社保、公积金等各项人力资源政策方案，时刻把握政策变动，及时追踪社保增
              </div>
              <div class="detailWord detailContent">
                减结果，有效落实各个服务节点，做到全过程有效把控。
              </div>
              <div class="detailWord detailContent">
                邦芒旗下共开设228家直营分公司，400+城市社保公积金可落地，100%匹配客户单位所有门店，属地分公司一对一管理社保公积金账户，充分保证生育报销、
              </div>
              <div class="detailWord detailContent">
                工伤申报的时效性。不仅快谏解决了招聘人员及员工在五险一金上的个性化问题，也及时响应了社保公积金账户开立需求，让人事从繁杂的社保事务中脱离出
              </div>
              <div class="detailWord detailContent">
                来。
              </div>
              <img src="@/assets/enterprise2.png" class="detailImg detailImg2">
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="detail">
            <div class="detailItem">
              <div class="detailTitle">餐饮行业老牌企业</div>
              <div class="detailP">服务客户</div>
              <div class="detailWord">知名餐饮集团，成立于二十世纪九十年代，经过数十年，发展为“500强”股份制企业。经营区域涉及北京、上海、天津、重庆、广东等多个省市。</div>
              <div class="detailP">客户痛点</div>
              <div class="detailWord">1.员工基数大、流动量大，基础人事工作繁杂，入离职手续办理、人事档案管理，社保公积金增减都需要及时跟进。</div>
              <div class="detailWord">2.项目点分布各地，需要安排临时工，用工风险高。</div>
              <div class="detailWord">3.临时工的薪资发放工作量巨大，不同项目点结算标准不同，结算周期不同，人事算薪面临频次高，标准不一的问题，财务按期支付压力大。</div>
              <div class="detailP">解决方案</div>
              <div class="detailWord detailContent">
                邦芒配备专业化的服务团队，提供数据化、系统化、属地化的专业人力资源服务。运用网签系统，简化线下复杂签署环节，对未在规定时间签署合同的员工，
              </div>
              <div class="detailWord detailContent">
                系统自动提醒签，有效减少了用工风险。
              </div>
              <div class="detailWord detailContent">
                邦芒运用自主研发的人力资源信息管理系统--人事宝云平台，集成全国400+城市社保、公积金等各项人力资源政策方案，一键申报海量员工的社保、公积金、
              </div>
              <div class="detailWord detailContent">
                商业险的增员、减员，有效缓解人事工作量大的问题。
              </div>
              <div class="detailWord detailContent">
                针对临时工问题，邦芒制定针对性商业险方案，不仅避免了用工风险，而且降低投保普通商业险方案的成本。邦芒自主研发的发薪平台，设定不同项目点发薪
              </div>
              <div class="detailWord detailContent">
                公式及发薪节点，能够做到一键发薪、准确发薪、按时发薪。同时，发薪平台打通100+银行端口，支持发薪到支付宝、银行卡，解决了临时工收款账户限制
              </div>
              <div class="detailWord detailContent">
                的问题。
              </div>
              <img src="@/assets/enterprise3.png" class="detailImg detailImg3">
            </div>
          </div>
        </div>
      </div>
      <!--分页器-->
      <div class="auto">
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div class="process">
      <div class="processTitle">服务流程与保障</div>
      <div class="processWord">标准化服务流程保障</div>
      <div class="processLine"></div>
      <div class="processFlex">
        <div>
          <img src="@/assets/process1.png" class="processImg">
          <div class="processContent">在线咨询</div>
        </div>
        <img src="@/assets/arrow.png" class="arrow">
        <div>
          <img src="@/assets/process2.png" class="processImg">
          <div class="processContent">制定专属方案</div>
        </div>
        <img src="@/assets/arrow.png" class="arrow">
        <div>
          <img src="@/assets/process3.png" class="processImg">
          <div class="processContent">签订服务协议</div>
        </div>
        <img src="@/assets/arrow.png" class="arrow">
        <div>
          <img src="@/assets/process4.png" class="processImg">
          <div class="processContent">实施服务</div>
        </div>
        <img src="@/assets/arrow.png" class="arrow">
        <div>
          <img src="@/assets/process5.png" class="processImg">
          <div class="processContent">支付结算</div>
        </div>
        <img src="@/assets/arrow.png" class="arrow">
        <div>
          <img src="@/assets/process6.png" class="processImg">
          <div class="processContent">企业用工过程支持</div>
        </div>
      </div>
    </div>
    <div class="footWord">
      <div class="footWordItem">
        邦芒服务外包有限公司
      </div>
    </div>
    <!-- <foots :type="3" @navChange = "navChange"></foots> -->
  </div>
</template>
<script>

import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";

import { first } from "lodash";

import debounce from 'lodash/debounce'
import _service from "@/service";
export default {
  name: "About",
  components: {
    titles,
    foots
  },
  data() {
    return {
      activeName: "first",
      type:'',
      messageData: {
        contacts: "",
        phone: "",
        email: "必应搜索",
        msg: "",
        cityName:"",
        type: null
      },
    };
  },
  mounted(){
    window.scrollTo(0,0);
    this.type = this.$route.query.type
    if(this.type){
      this.initTabs(this.type)
    }
    var mySwiper = new Swiper('.swiper', {
      autoplay: true,//可选选项，自动滑动
      loop: true, //开启循环滚动，从头尾相接
      pagination: { //分页器 ——请勿全部复制，根据需要添加子项
        el: ".swiper-pagination",
        // clickable设置为true时，点击分页器的指示点分页器会控制Swiper切换
        clickable :true,
        //修改分页器样式
        bulletClass : 'swiper-pagination-bullet',
        //激活样式
        bulletActiveClass: 'my-bullet-active',
      },
    })
  },
  computed: {
    messageDataRules() {

      var phoneJy = (rule,value,callback) =>{
        var myreg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        debugger
					if (!value) {
						return callback(new Error('请输入必填项'))
					}
					if (!myreg.test(value)) {
						return callback(new Error('格式不正确！'))
					}
					callback()
      }


      return {
        contacts: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur"
          },
        ],
        phone: [
          {
            required: true,
						validator: phoneJy,
						trigger: 'blur'
          },
        ],
        type: [
          {
            required: true,
            message: "你是公司还是个人？",
            trigger: "blur"
          },
        ],
      }
    }
  },
  methods: {
    messageSubmit: debounce(function () {
      this.$refs['messageData'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.messageData.columnValue = 0
        this.messageData.source = "www.50bm.com.cn";
        _service.messageSubmit({ ...this.messageData }).then((res) => {
          if (!res) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.messageData = {}
            return;
          }
          this.$message.error(`提交失败`);
        });
      })
    }),
    messageHandle() {
      window.scrollTo(0,620);
    },
    navChange(e){
      this.initTabs(e)
    },

    initTabs(e){
      if(e == 1){
        this.activeName = "first"
      }
      if(e == 2){
        this.activeName = "second"
      }
      if(e == 3){
        this.activeName = "third"
      }
      if(e == 4){
        this.activeName = "fourth"
      }
      if(e == 5){
        this.activeName = "fifth"
      }
      window.scrollTo(0,0);
    }
  },
};
</script>



<style lang="scss">
.about {
  width: 100%;
}
.navAll {
position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #ffffff;
  width: 100%;
}
.nav {
  width: 1200px;
  height: 72px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .logo { 
    width: 136px;
    height: 72px;
  }
  .msg {
    border: 1px solid #CDCDCD;
    border-radius: 32px;
    width: 140px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Microsoft YaHei;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.12px;
    text-align: left;
    color: #333333;
  }
  .msg:hover {
    background-color: #C7191A;
    color: #ffffff;
  }
}
.banner {
  position: relative;
  height: 620px;
  margin-top: 72px;
  background-color: #EEF2FB;
  .bannerMessage {
    width: 1920px;
    height: 620px;
    position: absolute;
    left: 50%;
    margin-left: -960px;
  }
  .wordAll {
    width: 1200px;
    height: 620px;
    position: absolute;
    top: 0;
    left: calc(50% - 600px);
    .title {
      font-family: Microsoft YaHei;
      font-size: 40px;
      font-weight: 700;
      line-height: 52.79px;
      text-align: left;
      color: #333333;
      position: absolute;
      top: 140px;
    }
    .pWord {
      font-family: Microsoft YaHei;
      font-size: 24px;
      font-weight: 400;
      line-height: 48px;
      text-align: left;
      color: #333333;
      position: absolute;
      top: 231px;
      width: 515px;
    }
    .spanWord {
      font-family: Microsoft YaHei;
      font-size: 16px;
      font-weight: 290;
      line-height: 32px;
      text-align: left;
      color: #333333;
      position: absolute;
      top: 335px;
      width: 501px;
    }
  }
}
.business {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  
  .businessImg {
    margin-top: 106px;
    margin-bottom: 124px;
  }
  .businessRight {
    
    width: 684px;
    height: 420px;
    border-radius: 32px;
    background-color: #F6F6F6;
    .businessTitle {
      font-family: Microsoft YaHei;
      font-size: 26px;
      font-weight: 700;
      line-height: 34.32px;
      color: #333333;
      margin-top: 29px;
      margin-bottom: 19px;
    }
    .businessContent {
      font-family: Microsoft YaHei;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.12px;
      color: #666666;
      margin-bottom: 27px;
    }
    .businessForm {
      .message_item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .bitian {
          display: flex;
          align-items: baseline;
          justify-content: center;
          margin-left: 64px;
          .red {
            color: #ff0000;
          }
        }
        .bitianInput {
          width: 509px;
          height: 48px;
          margin-left: 18px;
        }
      }
      .message_submit {
        width: 509px;
        height: 48px;
        margin: 0 auto;
        border-radius: 32px;
        background-color: #C7191A;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.12px;
        color: #ffffff;
      }
    }
  }
}

.serveColor {
  height: 560px;
  background: #F6F6F6;
  .serve {
    width: 1200px;
    margin: 0 auto;
    background: #F6F6F6;
    .serveTop {
      padding-top: 62px;
      .serveTitle {
        font-family: Microsoft YaHei;
        font-size: 26px;
        font-weight: 700;
        line-height: 34.32px;
        text-align: center;
        color: #333333;
      }
      .lineRed {
        width: 60px;
        height: 4px;
        border-radius: 6px 0px 0px 0px;
        background-color: #C7191A;
        text-align: center;
        margin: 17px auto 53px;
      }
    }
    .serveAll {
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      width: 1200px;
      .serveItem {
        display: flex;
        background-color: #ffffff;
        width: 580px;
        height: 124px;
        margin-bottom: 32px;
        .serveImg {
          width: 60px;
          height: 60px;
          border-radius: 8px 0px 0px 0px;
          margin-top: 33px;
          margin-left: 32px;
        }
        .wordTitle {
          margin-top: 33px;
          margin-left: 29px;
          text-align: left;
          font-family: Microsoft YaHei;
          font-size: 18px;
          font-weight: 700;
          line-height: 23.76px;
          color: #333333;
        }
        .wordTitleContent {
          margin-top: 7px;
          margin-left: 29px;
          text-align: left;
          font-family: Microsoft YaHei;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color:#666666;
        }
      }
    }
  }
}

.supplier {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 105px;
  .supplierTitle {
    margin-bottom: 72px;
    margin-top: 74px;
    font-family: Microsoft YaHei;
    font-size: 26px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: #333333;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    .left {
      width: 560px;
      height: 396px;
      border-radius: 32px 0px 0px 0px;
    }
    .question {
      .questionItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 585px;
        height: 56px;
        background-color: #F5F5F5;
        margin-bottom: 12px;
        border-radius: 20px;
        .wordS {
          margin-left: 31px;
        }
        .Frame {
          width: 26px;
          height: 26px;
          margin-right: 33px;
        }
      }
    }
  }
}

.cost {
  width: 1200px;
  margin: 0 auto;
  .costTitle {
    margin-bottom: 59px;
    .costBackground {
      position: relative;
      font-family: Microsoft YaHei;
      font-size: 32px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0.06em;
      text-align: center;
      color: #E8E8E8;
    }
    .costBackground::after {
      content: "“选邦芒人力，有效为您降低用工成本30%”";
      position: absolute;
      top: 16px;
      left: 50%;
      margin-left: -284px;
      z-index: 1;
      font-family: Microsoft YaHei;
      font-size: 26px;
      font-weight: 700;
      line-height: 50px;
      text-align: center;
      color: #333333;
    }
  }
  .Group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 59px;
    .GroupItem {
      width: 220px;
      height: 260px;
      border-radius: 32px;
      .GroupWord {
        width: 220px;
        height: 70px;
        border-radius: 32px 32px 0px 0px;
        background-color: #D4B391;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
      }
      .GroupBottom {
        background-color: #F5F5F5;
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
        height: 158px;
        padding-top: 32px;
        .GroupWord2 {
          font-family: Microsoft YaHei;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
          color: #333333;
          line-height: 28px;
          height: 28px;
        }
        .GroupImg {
          width: 48px;
          height: 48px; 
          padding: 2.33px 0.34px 2.33px 0px;
          margin-top: 27px;
        }
      }
    }
  }
}

.num {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 97px;
  .numItem {
    position: relative;
    background-image: url('../assets/borderLine.png');
    background-size: cover; /* 背景图片覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图片不重复 */
    background-position: center; /* 背景图片居中 */
    width: 176px;
    height: 164px;
    .numTitle {
      position: absolute;
      top: 12px;
      left: 22px;
      font-family: Microsoft YaHei;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      color: #AF9575;
    }
    .numFlex {
      position: absolute;
      top: 73px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 176px;
      .numWord {
        font-family: Microsoft YaHei;
        font-size: 36px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        color: #B21E29;
      }
      .numUnit {
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        color: #B21E29;
      }
    }
  }
}
.auto {
  display: flex;
  justify-content: center;
  margin-top: -36px;
  .swiper-pagination {
  }
}

.my-bullet-active{ 
  background: #DDDDDD; 
  opacity: 1; 
} 
.swiper-pagination-bullet{ 
  width: 12px;
  height: 12px;
  background: #B21E29; 
  margin-left: 8px;
  margin-right: 8px;
} 
.detail {
  background-color: #F6F6F6;
  padding-top: 50px;
  padding-bottom: 58px;
  .detailItem {
    width: 1156px;
    // height: 824px;
    // height: 862px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 24px;
    padding-top: 38px;
    padding-left: 44px;
    text-align: left;
    .detailTitle {
      font-family: Microsoft YaHei;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
      color: #333333;
      margin-bottom: 19px;
    }
    .detailP {
      font-family: Microsoft YaHei;
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
      color: #B21E29;
      margin-bottom: 3px;
    }
    .detailWord {
      font-family: Microsoft YaHei;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      color: #666666;
    }
    .detailContent {
      width: 1142px;
    }
    .detailImg {
      margin-top: 20px;
      width: 1120px;
      height: 270px;
      text-align: left;
      margin-bottom: 29px;
    }
    .detailImg2 {
      margin-top: 84px;
    }
    .detailImg3 {
      margin-top: 52px;
    }
  }
}

.process {
  margin-bottom: 114px;
  .processTitle {
    font-family: Microsoft YaHei;
    font-size: 36px;
    font-weight: 700;
    line-height: 47.51px;
    color: #333333;
    margin-bottom: 10px;
  }
  .processWord {
    font-family: Microsoft YaHei;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.76px;
    color: #666666;
    margin-bottom: 14px;
  }
  .processLine {
    width: 120px;
    height: 4px;
    background: #B21E29;
    margin: 0 auto 95px;
  }
  .processFlex {
    width: 1160px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .processImg {
      width: 100px;
      height: 100px;
    }
    .processContent {
      margin-top: 25px;
      font-family: Microsoft YaHei;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #333333;
    }
    .arrow {
      width: 80px;
      height: 10px;
      margin-top: -49px;
    }
  }
}

.footWord {
  background: #E7E7E7;
  width: 100%;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  .footWordItem {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Microsoft YaHei;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #333333;
  }
}

.about_nav {
  background: #fff;
  border-bottom: 3px solid #e0e0e0;
  height: 75px;
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__nav{
    float: inherit;
  }
  .el-tabs__item{
    padding: 0 100px;
    font-size: 18px;
    color: #696969;
  }
  .el-tabs__header{
    margin: 0;
  }
  .el-tabs__active-bar{
    background-color: #d70035;
  }
  .el-tabs__item.is-active{
    color:#696969 ;
    font-weight: bold;
  }
  .el-tabs__item:hover{
    color: #696969;
  }
  .el-tabs{
    height: 75px;
    line-height: 75px;

  }
}
</style>




<style lang="scss" scoped>
.about_body_box{
  padding-top: 80px;
  background: #f2f2f2;
}
.el-form-item {
  margin-bottom: 24px;
}
::v-deep .el-form-item__error {
  color: var(--el-color-danger);
  font-size: 12px;
  line-height: 1;
  padding-top: 2px;
  position: absolute;
  top: 100%;
  left: 118px !important;
}
</style>